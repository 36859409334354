<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="addItem">新增</el-button>
      <!-- <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button> -->
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #active>
        <el-table-column label="是否启用" width="230" align="center">
          <template #default="scoped">
            {{ scoped.row.active ? "启用" : "禁用" }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small" @click="editItem(scoped.row)">
              编辑
            </el-button>
            <el-button type="primary" size="small" @click="jinItem(scoped.row)">
              {{ !scoped.row.active ? "启用" : "禁用" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="labelVisible"
      :title="title"
      width="50%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="writeoff_box">
        <span><i>*</i>字典编码：</span>
        <el-input
          v-model="from.groupCode"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>字典值：</span>
        <el-input
          v-model="from.groupName"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span>备注：</span>
        <el-input
          v-model="from.remark"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
          :rows="4"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from "vue";
import TableView from "@/components/TableView.vue";
import FormView from "@/components/FormView.vue";
import { ElMessage } from "element-plus";
import { dictAdd, dictEdit, dictList } from "@/api/getUser/dictionaryManagedData";
const initData = reactive({
  // formLabels: [
  //   {
  //     label: "keywords",
  //     title: "字典名称",
  //     placeholder: "请输入字典名称",
  //   },
  // ],
  fromData: {
    // 表单数据
    keywords: "",
    way: "",
    status: "",
  },
  formColumn: 4,
  labelWidth: "90px",
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0,
  },
  isPaginationShow: true,
  rTableData: [
    {
      id: 1,
    },
  ],
  columns: [
    { prop: "id", label: "ID" },
    { prop: "groupCode", label: "字典编码" },
    { prop: "groupName", label: "字典值" },
    { prop: "remark", label: "备注" },
    // { prop: "active", label: "是否启用" },
    { slot: "active" },
    { slot: "action" },
  ],
  from: {
    groupCode: "",
    groupName: "",
    remark: "",
  },
  labelVisible: false,
  title: "",
  id: "",
});

onMounted(() => {
  getDataList();
});

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: "",
    way: "",
    status: "",
  };
  pagination.value.current = 1;
  pagination.value.size = 10;
  getDataList();
};

// 修改状态
const editItem = (row) => {
  title.value = "修改";
  from.value = {
    groupCode: row.groupCode,
    groupName: row.groupName,
    remark: row.remark,
  };
  id.value = row.id;
  labelVisible.value = true;
};

const jinItem = (row) => {
  const data = {
    groupCode: row.groupCode,
    groupName: row.groupName,
    active:!row.active,
    remark: row.remark,
    id: row.id,
  };
  dictEdit(data).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg);
      getDataList();
    } else {
      ElMessage.error(res.msg);
    }
  });
};

// 新增
const addItem = () => {
  from.value = {
    groupCode: "",
    groupName: "",
    remark: "",
  };
  title.value = "新增";
  labelVisible.value = true;
};

const labelClose = () => {
  from.value = {
    groupCode: "",
    groupName: "",
    remark: "",
  };
  labelVisible.value = false;
};

// 提交
const remarkSubmit = () => {
  if (from.value.groupCode == "") {
    ElMessage.warning("请输入字典编码");
    return false;
  }
  if (from.value.groupName == "") {
    ElMessage.warning("请输入字典值");
    return false;
  }
  const data = {
    groupCode: from.value.groupCode,
    groupName: from.value.groupName,
  };
  if (from.value.remark != "") {
    data.remark = from.value.remark;
  }
  if (id.value == "") {
    dictAdd(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg);
        getDataList();
        labelVisible.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    });
  } else {
    data.id = id.value;
    dictEdit(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg);
        getDataList();
        labelVisible.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    });
  }
};

// 查询
const search = () => {
  pagination.value.current = 1;
  pagination.value.size = 10;
  getDataList();
};

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true;
  if (flag == "size") {
    pagination.value.size = val || 10;
  } else {
    pagination.value.current = val || 1;
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size,
  };
  if (fromData.value.keywords !== "") {
    data.keyword = fromData.value.keywords;
  }
  dictList(data).then(({ data: res }) => {
    loading.value = false;
    if (res.code == 200) {
      rTableData.value = res.data.records;
      pagination.value.total = Number(res.data.pageInfo.total);
    } else {
      ElMessage.error(res.msg);
      rTableData.value = [];
      pagination.value.total = 0;
    }
  });
};

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  from,
  labelVisible,
  title,
  id,
} = toRefs(initData);
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 30px;

  span {
    width: 100px;
    text-align: right;
  }

  .dialog_input {
    width: 50%;
  }

  i {
    color: red;
  }
}

.remark_box {
  align-items: flex-start;
}
</style>

import request from '../Axios'

// 新增
export const dictAdd = data => {
  return request({
    url: '/dict/data',
    method: 'post',
    data
  })
}

// 修改
export const dictEdit = params => {
  return request({
    url: `/dict/data`,
    method: 'put',
    data: params
  })
}

// 查询
export const dictList = () => {
  return request({
    url: '/dict/data',
    method: 'get'
  })
}
